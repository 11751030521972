/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import {
  breakpoints,
  colors,
  fontFamilies,
  fontSizes,
  fontWeights,
  unitConverter as uc,
} from '../../../../styles/base';
import Section from '../../../molecules/Section/Section';
import PageWrapper from '../../PageWrapper/PageWrapper';
import Text from '../../../atoms/Text/Text';
import { Heading4 } from '../../../atoms/Headings/Headings';
import blueDotsImage from '../assets/blueDots.png';
import Image from '../../../atoms/Image/Image';
import sanityImage from '../../../../utils/sanityImage';

const TimelineItemsByYear = ({ allTimelineItems, sectionStyles, year }) => {
  const sectionCss = css`
    margin: ${uc('88px 50px 0 50px')};

    @media (${breakpoints.tablet}) {
      margin-right: ${uc('30px')};
      margin-left: ${uc('35px')};
    }
  `;

  const pageWrapperCss = css`
    display: flex;

    @media (${breakpoints.mobile}) {
      display: block;
    }
  `;

  const yearColumnCss = css`
    width: 30%;
    color: ${colors.primary};
    font-weight: ${fontWeights.semiBold};
    font-size: ${fontSizes.sixty};
    font-family: ${fontFamilies.proximaSoft};
    line-height: initial;

    @media (${breakpoints.tablet}) {
      width: 25%;
      font-size: ${fontSizes.forty};
    }

    @media (${breakpoints.mobile}) {
      position: relative;
      top: ${uc('-88px')};
      left: ${uc('90px')};
      width: 30%;
    }
  `;

  const timelineColumnCss = css`
    width: 100%;
  `;

  const timelineCss = css`
    position: relative;
    display: flex;
  `;

  const headingTextCss = css`
    margin-bottom: ${uc('5px')};
    color: ${colors.primary};
    font-family: ${fontFamilies.proximaSoft};

    @media (${breakpoints.tablet}) {
      font-size: ${fontSizes.sixteen};
    }

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.fourteen};
    }
  `;

  const textCss = css`
    color: ${colors.accent};
    font-weight: ${fontWeights.semiBold};
    font-family: ${fontFamilies.proximaSoft};

    @media (${breakpoints.mobile}) {
      font-size: ${fontSizes.twelve};
    }
  `;

  const middleDotsCss = css`
    margin-top: ${uc('5px')};
    margin-left: ${uc('60px')};

    @media (${breakpoints.tablet}) {
      margin-left: ${uc('30px')};
    }
  `;

  const awardCss = css`
    width: 100%;
    margin-left: ${uc('50px')};
  `;

  const logoCss = css`
    min-width: ${uc('240px')};
    text-align: right;

    @media (${breakpoints.tablet}) {
      min-width: ${uc('140px')};

      img {
        max-width: ${uc('140px')};
      }
    }

    @media (${breakpoints.mobile}) {
      min-width: ${uc('90px')};

      img {
        max-width: ${uc('90px')};
      }
    }
  `;

  const renderedTimeline = allTimelineItems
    ? allTimelineItems.map(timelineItem => (
        <div
          className="timeline-item"
          css={timelineCss}
          key={`timelineItem-${timelineItem._key}`}
        >
          <div css={logoCss}>
            <Image
              src={sanityImage(timelineItem.logo)
                .auto('format')
                .width(200)
                .fit('max')
                .url()}
            />
          </div>
          <div className="dots-image">
            <Image css={middleDotsCss} src={blueDotsImage} />
          </div>
          <div css={awardCss}>
            <Heading4 css={headingTextCss}>{timelineItem.headingText}</Heading4>
            <Text css={textCss}>{timelineItem.awardType}</Text>
          </div>
        </div>
      ))
    : null;

  return (
    <Section sectionStyles={sectionStyles} css={sectionCss}>
      <PageWrapper css={pageWrapperCss}>
        <div css={yearColumnCss}>{year}</div>
        <div className="timeline-column" css={timelineColumnCss}>
          {renderedTimeline}
        </div>
      </PageWrapper>
    </Section>
  );
};

TimelineItemsByYear.propTypes = {
  year: PropTypes.string.isRequired,
  sectionStyles: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.shape({})),
    PropTypes.shape({}),
  ]),
  allTimelineItems: PropTypes.arrayOf(PropTypes.shape([])),
};

TimelineItemsByYear.defaultProps = {
  sectionStyles: {},
  allTimelineItems: [],
};

export default TimelineItemsByYear;
