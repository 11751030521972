/** @jsx jsx this comment is required for Storybook to render */
import { css, jsx } from '@emotion/core';
import PropTypes from 'prop-types';
import TimelineItemsByYear from './TimelineItemsByYear/TimelineItemsByYear';
import startDotsImage from './assets/dotsStart.png';
import endDotsImage from './assets/dotsFade.png';
import { breakpoints, unitConverter as uc } from '../../../styles/base';

const TimelineSection = ({ timelineItemsByYear }) => {
  const timelineContainerCss = css`
    section {
      .timeline-column > div:first-of-type {
        .dots-image::before {
          content: url(${startDotsImage});
          position: absolute;
          top: ${uc('-83px')};
          padding-left: ${uc('60px')};

          @media (${breakpoints.tablet}) {
            padding-left: ${uc('30px')};
          }
        }
      }
    }

    section:last-of-type {
      position: relative;
      margin-bottom: ${uc('200px')};

      .timeline-column > div:last-of-type {
        .dots-image::after {
          position: absolute;
          top: ${uc('130px')};
          left: ${uc('281px')};
          content: url(${endDotsImage});

          @media (${breakpoints.tablet}) {
            top: ${uc('71px')};
            left: ${uc('151px')};
          }

          @media (max-width: 450px) {
            top: ${uc('71px')};
            left: ${uc('100px')};
          }
        }
      }

      @media (${breakpoints.tablet}) {
        margin-bottom: ${uc('110px')};
      }
    }

    @media (${breakpoints.smallDesktopMin}) {
      margin-top: ${uc('-35px')};
    }

    @media (${breakpoints.tablet}) {
      margin-top: ${uc('-50px')};
    }

    @media (${breakpoints.mobile}) {
      margin-top: ${uc('120px')};
    }
  `;

  const renderedTimeline = timelineItemsByYear
    ? timelineItemsByYear.map(timelineItem => (
        <TimelineItemsByYear
          key={`timelineItemsByYear-${timelineItem._key}`}
          year={timelineItem.year}
          sectionStyles={timelineItem.sectionStyles}
          allTimelineItems={timelineItem.allTimelineItems}
        />
      ))
    : null;

  return <div css={timelineContainerCss}>{renderedTimeline}</div>;
};

TimelineSection.propTypes = {
  timelineItemsByYear: PropTypes.shape([]),
};

TimelineSection.defaultProps = {
  timelineItemsByYear: [],
};

export default TimelineSection;
